<template>
  <LoginComponent />
</template>

<script>
import LoginComponent from "../modules/login/Login";
export default {
  name: "login",
  components: {
    LoginComponent,
  },
};
</script>
